.media-item-table {
	margin-top: #{$spacing-unit}px;
	//remove the filter row on media items as it's unused
	.dx-datagrid-filter-row {
		display: none;
	}

	.dx-datagrid .dx-row > td {
		line-height: unset;
	}

	.source-cell {
		display: flex;

		.appeared-count {
			margin-bottom: 3px;
		}

		.source-logo {
			.avatar {
				margin-left: 7.5px;
				margin-right: 7.5px;
			}
		}
	}
}

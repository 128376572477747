//override the theme variables with the local project themes
@import '~@truescope-web/react/lib/scss/theme.scss';
@import 'theme.scss';

//override the theme variables with the local project themes
@import '~@truescope-web/react/lib/scss/typography.scss';
@import 'typography.scss';

@import '~@truescope-web/react/lib/scss/styles.scss';
@import 'components/article-panel.scss';
@import 'components/drawer.scss';
@import 'components/dx-table.scss';
@import 'components/media-player.scss';
@import 'components/query-condition-groups-builder.scss';
@import 'components/reactTable.scss';
@import 'components/scopesPanel.scss';
@import 'components/queriesPanel.scss';
@import 'components/mediaItem.scss';
@import 'components/mediaItemBuilder.scss';
@import 'components/json-viewer.scss';
@import 'components/imageUploadCropper.scss';
@import 'components/table/clientUserWorkspacesTable.scss';
@import 'components/table/clientUsersTable.scss';
@import 'components/table/mediaItemTable.scss';
@import 'components/source.scss';

@import 'components/workspace/workspace-content-rights.scss';
@import 'components/workspace/workspace-scopes.scss';

@import 'views/Dashboards/dashboard.scss';
@import 'views/ClientUser/client-user.scss';
@import 'views/Entities/sheet-content.scss';
@import 'views/Entities/sources.scss';
@import 'views/Entities/EntitiesList/Entity.scss';
@import 'views/Search/search.scss';
@import 'views/SignIn/sign-in.scss';

@import 'views/SocialHandlesManager/social-handles-manager.scss';

body {
	background: $app-background;
	margin: 0;
	color: $font-color;
	font-family: $font-family;
	overflow: hidden;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.app {
	display: flex;
}

.heading-colour,
.clickable {
	color: $font-color;
	&:hover {
		text-decoration: none;
		//color: lighten($font-color, 5%);
		color: $primary-color;
	}
}

.app-loading-container {
	height: 90vh;
}

hr {
	border-top: 0px;
	margin: #{$spacing-unit * 3}px 0px;
}

/* BUTTON */
button.button {
	border: 0px;
	padding: 7px 12px;
	text-transform: uppercase;
	font-weight: $weight-bold;
}

.view {
	overflow-y: auto;
}

.capitalize {
	text-transform: capitalize;
}

//todo: this needs to be fixed, the styles aren't appearing quite properly
.snackbar {
	.icon {
		font-size: 20px;
		&--variant {
			opacity: 0.9;
			margin-right: #{$spacing-unit}px;
		}
	}
	.message {
		display: flex;
		align-items: center;
	}
	&--success {
		background-color: $confirm-background;
	}
	&--warning {
		background-color: $warning-background;
	}
	&--info {
		background-color: $primary-background;
	}
	&--error {
		background-color: $error-background;
	}
}

.full-width {
	width: 100%;
}

.full-width-loader {
	display: flex;
	width: 100%;
	justify-content: center;
}

.link {
	cursor: pointer;
}

//temporary fix to get around this super annoying issue.
//https://github.com/facebook/create-react-app/issues/11773
//TODO check back in 3 months time to see if this is still an issue. around 01/06/22
body > iframe {
	pointer-events: none;
}

//stops the buttons from bouncing around when setting them
.button-group {
	button.button {
		border: unset !important;
		&.MuiButton-root:hover {
			border: unset !important;
		}
	}
}

.partial-loading {
	position: relative;
	height: 4px;
	width: 100%;
	&__linear-progress {
		width: 100%;
		position: absolute;
	}
}

.select.select--is-disabled .select__single-value p {
	color: $font-disabled;
}
